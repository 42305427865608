import newRelease349 from '../assets/Zeugnisprozess_Release_3_4_9.pdf'
import newRelease3 from '../assets/Zeugnisprozess_Release_3.pdf'
import kurzinfo from '../assets/ZEP3_Kurzinfo.pdf'
import newRelease3812 from '../assets/Zeugnisprozess_Release_3_8_12.pdf'
import prozessAnwendung from '../assets/ZEP_Prozess_Anwendung.pdf'

export const downloads = [
    {
        title: 'Kurzinfo',
        text: 'Das Wichtigste in Kürze.',
        file: kurzinfo,
        fileType: 'PDF'
    },
    {
        title: 'Neuer Release 3.8.12',
        text: 'Die wichtigsten Neuerungen',
        file: newRelease3812,
        fileType: 'PDF'
    },
    {
        title: 'Release 3.4.9',
        text: 'Die wichtigsten Neuerungen',
        file: newRelease349,
        fileType: 'PDF'
    },
    {
        title: 'Release 3.0',
        text: 'Die wichtigsten Neuerungen',
        file: newRelease3,
        fileType: 'PDF'
    },
    {
        title: 'Der Prozess und die Anwendung',
        text: 'ZEP proudly presented by …..',
        file: prozessAnwendung,
        fileType: 'PDF'
    },
]