import React from 'react'
import Layout from '../components/layout'

import { downloads } from '../conf/downloads'

import pdf from '../images/pdf.png'

const renderDownloads = () => {
  return downloads.map((post, index) => {
    return (
      <div className="col-md-6 col-sm-12" style={{ marginBottom: '24px' }} key={"post_" + index}>
        <div className="download-card">
          <img src={pdf} height="40px" width="40px" alt="pdf" />
          <h4>{post.title}</h4>
          <h5>{post.text}</h5>
          <a href={post.file} target="_blank" rel="noopener noreferrer" className="button-link">Download</a>
        </div>
      </div >
    )
  })
}


const DownloadForm2 = () => (
  <Layout>
    <section id="downloads" className="section-wrapper">
      <header className="section-header">
        <h3>Downloads</h3>
        <p>Hier finden Sie ...</p>
      </header>
      <div className="row">
        {renderDownloads()}
      </div>
    </section>
  </Layout>
)


export default DownloadForm2
